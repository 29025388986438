<template>
    <div class="card shadow border-0 mb-3">
        <div class="card-body">
            <h4 class="mb-3">Carrito</h4>

            <div class="py-2 product-card" v-for="item in store.currentBudget?.products?.data" :key="item.id">
                <p class="fw-bold text-capitalize mb-0 item-name small">{{ item.name }}</p>
                
                <div class="justify-content-start mt-1 align-items-center d-flex">
                    <!-- <div class="image-product">
                        <img class="img-fluid rounded-circle" :src="`${baseURL}/${item.image}`" alt="">
                    </div> -->

                    <div>
                        <p class="text-theme small mb-0">{{ getCalculatedPrice(item) }}</p>
                    </div>

                    <div class="ms-auto quantity-selector">
                        <template v-if="getQuantity(item.id) != 0">
                            <small class="mx-2 text-muted">x{{ getQuantity(item.id) }}</small>
                            <button class="btn btn-outline-secondary btn-sm me-1" type="button" id="button-addon2" @click="store.removeToCart(item)">
                                <i class="bi bi-dash"></i>
                            </button>
                        </template>
                        
                        <button class="btn btn-outline-theme btn-sm" type="button" id="button-addon2" @click="store.addToCart(item)">
                            <i class="bi bi-plus"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="py-5 text-center text-muted" v-if="store.currentBudget?.products?.data.length <= 0">
                <i class="bi bi-cart h2"></i>
                <p>Todavía no hay productos</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useBudgetsStore } from "../../stores/budgets";
import { currencyFormatted } from '@/helpers/currency'
const store = useBudgetsStore();
const baseURL = process.env.VUE_APP_SERVER_BASE_URL;

const getCalculatedPrice = ({ price, pricePro, quantity }) => {
    if(store.currentBudget.user?.data.role === 'profesional')
        return currencyFormatted(pricePro * quantity)

    return currencyFormatted(price * quantity)
}

const getQuantity = (id) => {
    const item = computed(() => store.currentBudget.products.data.find(el => el.id === id))

    if(!item.value)
        return 0
        
    return item.value.quantity
}
</script>

<style scoped>
.image-product {
    width: 50px;
}
.image-product img {
    object-fit: cover;
    height: 40px;
    width: 40px;
}
.item-name {
    margin-bottom: -6px!important;
}
.quantity-product {
    min-width: 40px;
    text-align: center;
}
</style>