import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import { useGlobalStore } from './globalStore'
import { useLocalStorage } from '@vueuse/core'
import { useRouter } from 'vue-router'
import server from '../api/server'
import moment from 'moment'
import { useProductsStore } from './products'

export const useBudgetsStore = defineStore('budgetsStore', () => {
    const global = useGlobalStore()
    const budgets = ref([])
    const localBudgets = useLocalStorage('local-budgets', [])
    const currentBudget = ref(null)
    const isLoading = ref(true)
    const itemsPerPage = ref(15)
    const page = ref(1)
    const router = useRouter()
    const searchInput = ref('')

    const budgetsPaginated = computed( () => {
        let items = []
        
        if(searchInput.value.length > 2) {
            items = budgets.value.filter( (element) => {
                return element.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0 
                || element.email.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0
            })
        } else {
            items = budgets.value.slice((page.value - 1) * itemsPerPage.value, ((page.value - 1) * itemsPerPage.value) + itemsPerPage.value)
        }
        
        return items
    })

    async function getBudgets() {
        isLoading.value = true

        try {
            const { data } = await server.get(`/budgets`)
            const { status, results } = data

            if(!status) {
                console.log(err, 'error')
                return false
            }

            budgets.value = results.data
            isLoading.value = false
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    async function uploadStoredBudgets(form) {
        if(!global.onLine)
            return

        isLoading.value = true
        const formData = new FormData()
        for (const [key, value] of Object.entries(form)) {
            formData.append(key, value)
        }

        formData.append('products', JSON.stringify(form.products.data.map(x => x.id +'|'+x.quantity)))

        const { data } = await server.post('/budgets', formData)
        const { status, results } = data

        if(!status) 
            return false

        const index = localBudgets.value.findIndex( el => el.id == form.id) 
        localBudgets.value.splice(index, 1)
        budgets.value.unshift(results.data)

        swalToast("El presupuesto se ha subido correctamente a la nube.")
        isLoading.value = false
    }

    function getcurrentBudget(id){
        if(id != 'new')
        {
            currentBudget.value = budgets.value.find( el => el.id == id) 
        } else {
            currentBudget.value = {
                id: 'new',
                name: 'Título por defecto',
                user_id: undefined,
                tax: 10,
                products: { data: [] },
                user: { data: {} }
            }
        }
    }

    async function submitForm() {
        const form = currentBudget.value

        if(!global.onLine)
            return storeLocal(form)

        const formData = new FormData()
        for (const [key, value] of Object.entries(form)) {
            formData.append(key, value)
        }


        formData.append('budget_id', form.id)
        // Envio un array con el ID y cantidad separado por un simbolo \
        formData.append('products', JSON.stringify(currentBudget.value.products.data.map(x => x.id +'|'+x.quantity)))

        const action = (form.id === 'new') ? '/budgets' : '/budgets/update'
        const { data } = await server.post(action, formData)
        const { status, results, message } = data

        if(!status) 
            return false

        if(form.id === 'new') {
            budgets.value.unshift(results.data)
        } else {
            const update = budgets.value.findIndex( el => el.id == form.id) 
            budgets.value[update] = results.data
        }

        swalToast(message)
        router.push({name: 'budgets'})
       
    }

    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar este presupuesto?", "Los materiales vinculados a este presupuesto no se verán afectados", "Eliminar", "Cancelar", "warning")
        if(!confirmed) return;

        try {
            const { data } = await server.delete(`budgets/${id}`)
            const { status } = data

            if(!status) {
                console.log(err, 'error')
                return false
            }

            const index = budgets.value.findIndex( el => el.id == id) 
            budgets.value.splice(index, 1)
           
            swalToast("El presupuesto ha sido eliminado correctamente.")
            return true
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    function nextPage(){
        if(page.value >= Math.ceil(budgets.value.length / itemsPerPage.value) ) return;
        
        page.value++
    }

    function prevPage(){
        if((page.value - 1) < 1 ) return;
        
        page.value--
    }

    function changePage(value){
        page.value = value
    }

    function addToCart(item) {
        const index = currentBudget.value.products.data.findIndex(el => el.id === item.id)

        if(index != -1) {
            return currentBudget.value.products.data[index].quantity += 1
        }
        currentBudget.value.products.data.push({...item, quantity: 1})
    }

    function removeToCart(item) {
        const index = currentBudget.value.products.data.findIndex(el => el.id === item.id)

        if(currentBudget.value.products.data[index].quantity > 1) {
            return currentBudget.value.products.data[index].quantity -= 1
        }

        currentBudget.value.products.data.splice(index, 1)
    }

    function storeLocal(item) {
        const newId = Math.floor(Math.random() * 999999)
        localBudgets.value.push({ 
            ...item, 
            'id': newId,
            'budget_id': newId,
            'user_id': item.user_id,
            'user': item.user,
            'created_at': moment().format('DD/MM/Y'),
            'import': item.products.data.reduce((n, { price, quantity }) => n + (price * quantity), 0),
            'productsData': JSON.stringify(item.products.data.map(x => x.id +'|'+x.quantity))
        })
        
        router.push({name: 'offline'})
        swalToast(
            "Presupuesto generado correctamente.", 
            "success", 
            5000, 
            "Para subir el presupuesto a la plataforma, es necesario conectarse a Internet, ya que ha sido generado localmente."
        )
    }

    return { 
        budgets: computed(() => budgets.value.filter( el => el.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0)), 
        budgetsPaginated,
        currentBudget, 
        isLoading,
        itemsPerPage,
        localBudgets: computed (() => localBudgets.value),
        page,
        searchInput,
        addToCart,
        changePage,
        destroy,
        getBudgets, 
        getcurrentBudget,
        nextPage,
        prevPage,
        removeToCart,
        submitForm,
        uploadStoredBudgets,
    }
})
