<template>
    <div class="card shadow-lg mb-3">
        <div class="card-body">
            <div class="container text-center">
                <div class="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                    <div class="input-group m-2">
                        <label class="input-group-text">Ordenar</label>
                        <button class="btn" 
                            :class="usersStore.orderFilter == 'desc' ? 'btn-theme' : 'btn-outline-theme'"
                            :disabled="usersStore.orderFilter == 'desc'"
                            @click="usersStore.sortDirection('desc')">
                            <i class="bi bi-sort-down"></i>
                        </button>
                        <button class="btn"
                            :class="usersStore.orderFilter == 'asc' ? 'btn-theme' : 'btn-outline-theme'"
                            :disabled="usersStore.orderFilter == 'asc'"
                            @click="usersStore.sortDirection('asc')">
                            <i class="bi bi-sort-up"></i>
                        </button>
                        <select class="form-select" @change="usersStore.filter($event)">
                            <option selected value="0">por defecto</option>
                            <option value="name">por nombre</option>
                            <option value="email">por email</option>
                            <option value="role">por permisos</option>
                        </select>
                    </div>
                    <div class="input-group m-2">
                    <input type="text"
                        class="form-control"
                        placeholder="Buscar usuario por nombre o email"
                        v-model="usersStore.searchInput"/>
                        <span class="input-group-text">Buscar</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useUsersStore } from "../../stores/users";
const usersStore = useUsersStore();
</script>