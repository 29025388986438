<template>
    <tr>
        <th :colspan="colspan" class="py-5 text-center text-muted">
            <template v-if="globalStore.onLine">
                <i class="bi bi-funnel h2"></i>
                <p>{{ label }}</p>    
            </template> 
            <template v-else>
                <i class="bi bi-wifi-off h2"></i>
                <p>
                    No tienes conexión a internet. <br>
                    Asegurate de conectarte para almacenar la información previamente en el dispositivo.
                </p>  
            </template>   
        </th>
    </tr>
</template>

<script setup>
import { useGlobalStore } from '@/stores/globalStore';
const globalStore = useGlobalStore()
const props = defineProps({
    label: {
        type: String,
        default: 'No se han encontrado resultados...',
    },
    colspan: {
        type: String,
        default: "1"
    }
})
</script>