import { useGlobalStore } from '@/stores/globalStore';
import axios from 'axios'

const serverURL = process.env.VUE_APP_SERVER_API_URL

const server = axios.create({
    baseURL: serverURL,
})

server.interceptors.request.use( function(config) {
    const store = useGlobalStore()
    config.headers.Accept = 'application/json'
    config.headers.Authorization = `Bearer ${store.getToken}`
    return config
})

export default server