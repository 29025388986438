<template>
    <div class="card border-0 shadow">
        <div class="card-body table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th scope="col" class="fw-bold">Nombre</th>
                        <th scope="col" class="fw-bold">SKU</th>
                        <th scope="col" class="fw-bold">Actualizado el</th>
                        <th></th>
                    </tr>
                </thead>
                <TransitionGroup name="list" tag="tbody">
                    <tr v-for="(product, i) in productsStore.productsPaginated" :key="i">
                        <th scope="row" class="logo">
                            <img class="img-fluid rounded-circle" :src="`${baseURL}/${product.image}`" alt="">
                        </th>
                        <td>{{ product.name }}</td>
                        <td>{{ product.sku }}</td>
                        <td><i class="bi bi-clock-history"></i> {{ getLastUpdated(product.updated_at) }}</td>
                        <td class="text-end">
                            <button-icon icon="bi bi-eye" :canOnline="true" route="products-edit" :params="{id: product.id}" />
                        </td>
                    </tr>
                    <no-results v-if="productsStore.productsPaginated.length <= 0" colspan="5" key="no-results" />
                </TransitionGroup>
            </table>
            
            <pagination-nav-component 
                v-if="productsStore.products.length > 0" 
                :elements="productsStore.products.length" 
                :page="productsStore.page" 
                :itemsPerPage="productsStore.itemsPerPage" 
                @next-page="productsStore.nextPage" 
                @prev-page="productsStore.prevPage" 
                @change-page="productsStore.changePage"/>
        </div>
    </div>
</template>

<script setup>
import { getLastUpdated } from '../../helpers/moment'
import { useProductsStore } from "../../stores/products";
import ButtonIcon from "@/components/common/buttons/ButtonIcon.vue";
import NoResults from '@/components/common/tables/NoResults.vue'
import PaginationNavComponent from '../common/PaginationNavComponent.vue'

const productsStore = useProductsStore();
const baseURL = process.env.VUE_APP_SERVER_BASE_URL;
</script>

<style scoped>
    .logo {
    width: 65px;
    }
    .logo img {
        object-fit: cover;
        height: 40px;
        width: 40px;
    }
</style>