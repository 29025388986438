<template>
    <section class="mb-3">
        <div class="card shadow border-0 mb-3">
            <div class="card-body table-responsive">
                <h5 class="card-title mb-3">Presupuestos almacenados en el dispositivo</h5>
                <p class="card-text mb-3">Para subir el presupuesto a la plataforma, es necesario conectarse a Internet, ya que todos los listados a siguiente han sido almacenados localmente.</p>

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="fw-bold">Nº</th>
                            <th scope="col" class="fw-bold">Fecha</th>
                            <th scope="col" class="fw-bold">Usuario</th>
                            <th></th>
                        </tr>
                    </thead>

                    <TransitionGroup name="list" tag="tbody">
                        <tr v-for="(item, i) in store.localBudgets" :key="i">
                            <td>{{ item.id }}</td>
                            <td>{{ item.created_at }}</td>
                            <td>{{ item.user.data.name }}</td>
                            <td>{{ currencyFormatted(item.import) }}</td>
                        </tr>

                        <no-results 
                            v-if="store.localBudgets.length <= 0" 
                            key="no-results" />

                    </TransitionGroup>
                </table>
            </div>
        </div>
    </section>
</template>

<script setup>
import { currencyFormatted } from '@/helpers/currency'
import { useBudgetsStore } from "../../stores/budgets";
import ButtonIcon from "@/components/common/buttons/ButtonIcon.vue";
import NoResults from '@/components/budgets/NoResults.vue'

const store = useBudgetsStore();
</script>