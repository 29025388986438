<template>
    <div>
        <div class="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between mb-2">
            <div class="input-group">
                <input type="text"
                    class="form-control"
                    placeholder="Buscar producto por nombre o sku..."
                    v-model="productsStore.searchInput"/>
                <span class="input-group-text">Buscar</span>
            </div>
        </div>

        <div class="accordion p-0 accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button p-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Filtrar por categorías
                    </button>
                </h2>
                <div id="flush-collapseOne" ref="accordionContent" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        <div class="row">
                            <div class="col-6" 
                                v-for="item in categoriesStore.categories" 
                                :key="`category-item-filter-${item.id}`">
                                <span 
                                    :class="item.id === productsStore.getCategorySelected?.id ? 'active' : ''" 
                                    class="pointer d-flex justify-content-between align-items-center w-100 badge mb-1 mx-1 py-2 bg-light"
                                    @click="selectCat(item)">
                                    {{ item.name }}
                                    <i v-if="item.id === productsStore.getCategorySelected?.id ? 'active' : ''" class="bi bi-x"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="productsStore.getCategorySelected" class="d-flex justify-content-start align-items-center">
                    <button class="btn btn-outline-theme btn-remove-cat border-0" type="button" @click="selectCat(null)">
                        <i class="bi bi-x-circle"></i>
                    </button>
                    <p class="fw-bold p-2 text-theme mb-0">Seleccionado: {{ productsStore.getCategorySelected.name }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue"
import { useProductsStore } from "@/stores/products"
import { useCategoriesStore } from "@/stores/categories"
const productsStore = useProductsStore()
const categoriesStore = useCategoriesStore()
const accordionContent = ref()

const selectCat = (id) => {
    accordionContent.value.classList.remove('show')
    productsStore.setCategory(id)
}
</script>

<style scoped>
.badge {
    border: 1px solid #e9ecef;
    color: #212529;
    text-align: left;
}

.badge.active {
    background-color:#1c3c34!important;
    color: #ffff;
}
#flush-collapseOne {
    position: absolute;
    z-index: 9;
    background-color: #f8f8f8;
}
.btn-remove-cat {
    width: 26px;
}
</style>
