<template>
    <div class="card shadow border-0 mb-3">
        <div class="card-body">
            <fieldset>
                <h4 class="mb-3">Datos del presupuesto</h4>
                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Presupuesto Nº</label>
                    <p>{{ `${getYear(store.currentBudget.created_at)}${store.currentBudget.id}` }}</p>
                </div>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Usuario</label>
                    <p>{{ store.currentBudget.user.data.name }}</p>
                </div>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">IVA</label>
                    <p>{{ store.currentBudget.tax }}%</p>
                </div>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Título del presupuesto</label>
                    <p>{{ store.currentBudget.name }}</p>
                </div>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Fecha de creación</label>
                    <p>{{ dateFormatted(store.currentBudget.created_at) }}</p>
                </div>

                <div>
                    <ButtonSubmit 
                        class="w-100" 
                        icon="bi-save" 
                        label="Guardar presupuesto" 
                        @on-submit="$emit('on-submit', form)" />
                </div>  
            </fieldset>
        </div>
    </div>
</template>

<script setup>
import {useBudgetsStore} from "../../stores/budgets";
import {dateFormatted, getYear} from '@/helpers/moment'
import ButtonSubmit from '@/components/common/buttons/ButtonSubmit.vue';
const store = useBudgetsStore();
</script>