import { useGlobalStore } from '../../stores/globalStore'
import { swalToast } from '../../helpers/swal'

const isOffline = async( to, from, next) => {
    const globalStore = useGlobalStore()

    if(globalStore.onLine) {
        swalToast("Ya tienes conexión a internet.", 'error')
        return next({ name: 'login' })
    }
    next()
}

export default isOffline