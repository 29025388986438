<template>
    <div class="d-flex justify-content-between align-items-center">
        <nav v-if="elements > itemsPerPage">
            <ul class="pagination shadow rounded m-0">
                <li class="page-item pointer">
                    <a class="page-link border-0" @click="$emit('prev-page')"><i class="bi bi-caret-left-fill"></i></a>
                </li>
                
                <li class="page-item pointer"
                    v-for="idx in totalPages" 
                    :key="`item-page-${idx}`"
                    :class="page == idx ? 'active': ''">
                    <a v-if="idx == page - 1 || idx == page+1 || idx == page" class="page-link border-0" @click="$emit('change-page', idx)">{{ idx }}</a>
                </li>
                
                <li class="page-item pointer">
                    <a class="page-link border-0" @click="$emit('next-page')"><i class="bi bi-caret-right-fill"></i></a>
                </li>
            </ul>
        </nav>

        <div>
            Mostrando 
            <span v-if="elements > itemsPerPage">{{itemsPerPage}}</span>
            <span v-else>{{elements}}</span> 
            de {{ elements }}
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue'
    const emits = defineEmits(['next-page', 'prev-page', 'change-page'])
    const props = defineProps({
        elements: {
            type: Number,
            required: true,
        },
        page: {
            type: Number,
            required: true
        },
        itemsPerPage: {
            type: Number,
            required: true
        }
    })

    const totalPages = computed(() => Math.ceil(props.elements / props.itemsPerPage)) 
    
</script>

<style scoped>
.active>.page-link {
    background-color: #1c3c34;
    border-color: #1c3c34;
    color: #ffffff
}
.page-link {
    color: #1c3c34;
}
</style>