import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast } from '@/helpers/swal'
import { useLocalStorage } from "@vueuse/core"
import { useRouter } from 'vue-router'
import server from '../api/server'

export const useGlobalStore = defineStore('globalStore', () => {
    const router = useRouter()
    const auth = useLocalStorage('auth', {})
    const status = useLocalStorage('status', 'unauthorised')
    const isLoading = ref(true)
    const onLine = ref(navigator.onLine)
    const bearerToken = useLocalStorage('token', '')

    /**
     * Retorna un booleano según el estado de la autenticación.
     * En caso de tener un token en localstorage hará un inicio de sesion
     * automatico.
     * 
     * @return bool
     */
     const checkAuth = async() => {
        if(!onLine.value)
            return false

        if(bearerToken.value === null || status.value === 'unauthorised') 
            return false
            
            
        try {
            const { data } = await server.get('/auth/check')
            
            if(!data.status) 
                return false

            setAuth(data.user, 'authorised') 
            return true              
        } catch(err) {
            setAuth({}, 'unauthorised')
            bearerToken.value = null           
            return false
        }
	}

    const setOnline = (value) => {
        onLine.value = value
    }

    const setIsLoading = (value) => {
        isLoading.value = value
    }

    const submitLogin = async({email, password}) => {
        try {
            const { data } = await server.post('/auth/login', {email, password})
            const { status, token, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }
            
            bearerToken.value = token.accessToken
            setAuth(data.user, 'authorised') 
            router.push({ name: 'home' })
        } catch(err) {
            swalToast(err, 'error')
            return false
        }
        
        return true
    }

    const submitLostPassword = async(email) => {
        try {
            const { data } = await server.post('/auth/password/forget', { email })
            const { status, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }

            swalToast(message, 'success')

            return true
        } catch(err) {
            swalToast(err, 'error')
            return false
        }
    }

    const submitResetPassword = async(formData) => {
        try {
            const { data } = await server.post('/auth/password/reset', formData)
            const { status, message } = data

            if(!status) {
                swalToast(message, 'error')
                return false
            }

            swalToast(message, 'success')

            return true
        } catch(err) {
            swalToast(err, 'error')
            return false
        }
    }

    const logOut = () => {
        setAuth({}, 'unauthorised')
        bearerToken.value = null
        router.push({ name: 'login'} )
    }

    const setAuth = async(authArg, statusArg) => {
        console.log("Setting auth", authArg)
		auth.value      = authArg
		status.value    = statusArg
	}
    
    return { 
        auth: computed( () => auth.value ), 
        getToken: computed( () => bearerToken.value ),
        isLoading: computed( () => isLoading.value ), 
        onLine: computed( () => onLine.value ),
        status: computed( () => status.value ),
        checkAuth,
        logOut,
        setIsLoading,
        setOnline,
        submitLogin,
        submitLostPassword,
        submitResetPassword,
    }
})
