<template>
  <page-title-component title="Presupuestos" icon="coin" desc="Revisa el histórico de presupuestos generados." />

    <div class="container-lg mt-n10">
        <loading-card v-if="store.isLoading" />
        
        <Transition name="fade">
            <div v-if="!store.isLoading">
                <alert-local-budget />

                <filters-component />
                <button-add-new />
                <table-results />
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useBudgetsStore } from '@/stores/budgets'
import { useProductsStore } from '@/stores/products';
import AlertLocalBudget from '@/components/common/AlertLocalBudget.vue';
import ButtonAddNew from "@/components/budgets/ButtonAddNew.vue";
import FiltersComponent from "@/components/budgets/FiltersComponent.vue";
import LoadingCard from '../components/common/LoadingCard.vue'
import PageTitleComponent from '../components/layouts/PageTitleComponent.vue'
import TableResults from "@/components/budgets/TableResults.vue";

const store = useBudgetsStore();
const productStore = useProductsStore();

onBeforeMount( async() => {
    await productStore.getProducts()
    store.isLoading = false
})
</script>