<template>
  <page-title-component title="Productos" icon="boxes" desc="Listado de productos, consulta la información o modifica sus datos." />

    <div class="container-lg mt-n10">
        <loading-card v-if="productsStore.isLoading" />
        
        <Transition name="fade">
            <div v-if="!productsStore.isLoading">
                <filters-component />
                <button-add-new />
                <table-products />
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { useProductsStore } from "../stores/products";
import ButtonAddNew from '../components/products/ButtonAddNew.vue'
import FiltersComponent from '../components/products/FiltersComponent.vue'
import LoadingCard from '../components/common/LoadingCard.vue'
import PageTitleComponent from '../components/layouts/PageTitleComponent.vue'
import TableProducts from '../components/products/TableProducts.vue'
import { onBeforeMount } from "vue";

const productsStore = useProductsStore()

onBeforeMount( () => {
    productsStore.isLoading = false
})
</script>