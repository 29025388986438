<template>
    <page-title-component title="Productos" icon="boxes" desc="Listado de productos, consulta la información o modifica sus datos." />

    <Transition name="fade">
        <div class="container-lg mt-n10" >

            <loading-card v-if="!store.currentProduct.id" />

            <div class="row" v-if="store.currentProduct.id">
                <div class="col-md-4">
                    <name-component />
                    <featured-image-component @on-submit="handleFile" />
                    <categories-selector />
                </div>

                <div class="col-md-8">
                    <form-component @on-submit="submitForm" />
                    <delete-component />
                </div>

            </div>
        </div>
    
    </Transition>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useProductsStore } from '@/stores/products';
import DeleteComponent from '@/components/products/DeleteComponent.vue';
import FeaturedImageComponent from '@/components/products/FeaturedImageComponent.vue';
import LoadingCard from '@/components/common/LoadingCard.vue'
import NameComponent from '@/components/products/NameComponent.vue';
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import FormComponent from "@/components/products/FormComponent.vue";
import CategoriesSelector from '@/components/products/CategoriesSelector.vue'
import { useCategoriesStore } from "@/stores/categories";
import { useRouter } from "vue-router";

const props = defineProps({
    id: {
        type: String,
        required: true
    },
})

const fileUploaded = ref()
const store = useProductsStore()
const categoriesStore = useCategoriesStore()
const router = useRouter()

onBeforeMount( async() => {
    if(store.products.length <= 0 || categoriesStore.categories.length <= 0) {
        router.push({name: 'products'})
    }
    
    store.getCurrentProduct(props.id);
});

const submitForm = (data) => {
    const formData = { ...data, file: fileUploaded.value }
    store.submitForm(formData)
}

const handleFile = (event) => {
    fileUploaded.value = event.target.files[0]
}
</script>