<template>
   <footer class="my-5 text-muted text-center text-small">
      <div>
         <p class="mb-1">©{{ actualYear }} {{ appTitle }} - Versión {{ appVersion }}</p>
         <ul class="list-inline">
            <li class="list-inline-item"><a href="#">Aviso legal</a></li>
            <li class="list-inline-item"><a href="mailto:soporte@jaestic.com">Soporte</a></li>
         </ul>
      </div>

      <div class="image-container mb-1">
         <img class="img-fluid" src="@/assets/firma_kitdigital.png" alt="Firma Kit Digital">
      </div>
      <small class="text-muted">Financiado por el Programa KIT Digital. Plan de Recuperación, Transformación y Resiliencia de España «Next Generation EU»</small>
   </footer>
</template>

<script setup>
   const actualYear = new Date().getFullYear()
   const appTitle = process.env.VUE_APP_TITLE
   const appVersion = process.env.VUE_APP_VERSION
</script>

<style scoped>
.image-container {
   max-width: 750px;
   margin: auto;
}
</style>