<template>
    <page-title-component title="Dashboard" :desc="`Panel de control de ${appTitle}.`" />

    <div class="container-xl mt-n10">
        <alert-local-budget />

        <div class="row">
            <home-card-component 
                route="users"
                icon="people"
                label="Usuarios"
                descr="Ver usuarios" />
            
            <home-card-component 
                route="products"
                icon="boxes"
                label="Productos"
                descr="Ver productos" />

            <home-card-component 
                route="budgets"
                icon="coin"
                label="Presupuestos"
                descr="Ver presupuestos" />

        </div>
    </div>
</template>

<script setup>
import AlertLocalBudget from '@/components/common/AlertLocalBudget.vue';
import HomeCardComponent from '@/components/home/HomeCardComponent.vue'
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'

const appTitle = process.env.VUE_APP_TITLE
</script>