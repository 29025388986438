<template>
    <div class="card border-0 shadow mb-3">
        <div class="card-body">
            <span class="position-absolute top-0 end-0 m-3">
                <i class="bi bi-clock-history"></i>
                {{ getLastUpdated(store.currentProduct.updated_at) }}
            </span>

            <form v-if="form.id">
                <h5 class="mb-3">Información</h5>

                <div class="mb-3">
                    <label for="product-name" class="form-label">Nombre</label>
                    <input type="text" class="form-control" id="product-name" v-model="form.name">
                </div>

                <div class="mb-3">
                    <label for="product-description" class="form-label">Descripción</label>
                    <input type="text" class="form-control" id="product-description" v-model="form.description">
                </div>
                
                <div class="d-flex justify-content-between align-items-center col-gap">
                    <div class="mb-3 col">
                        <label for="product-sku" class="form-label">SKU</label>
                        <div class="input-group">
                            <input type="text" class="form-control" id="product-sku" v-model="form.sku">
                            <span class="input-group-text"><i class="bi bi-upc-scan"></i></span>
                        </div>
                    </div>

                    <div class="mb-3 col">
                        <label for="product-price" class="form-label">PVP</label>
                        <div class="input-group">
                            <input type="number" step=".01" class="form-control" id="product-price" v-model="form.price">
                            <span class="input-group-text"><i class="bi bi-currency-euro"></i></span>
                        </div>
                    </div>

                    <div class="mb-3 col">
                        <label for="product-price" class="form-label">PRECIO PROF SIN IVA</label>
                        <div class="input-group">
                            <input type="number" step=".01" class="form-control" id="product-price-pro" v-model="form.pricePro">
                            <span class="input-group-text"><i class="bi bi-currency-euro"></i></span>
                        </div>
                    </div>
                </div>

                <h5 class="my-3">Observaciones</h5>
                <div class="mb-3">
                    <textarea class="form-control" id="product-observations" v-model="form.observations"></textarea>
                </div>

                <div class="d-flex justify-content-end align-items-center col-gap">
                    <ButtonSubmit icon="bi-pencil" label="Actualizar" @on-submit="$emit('on-submit', form)" />
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { getLastUpdated } from '@/helpers/moment'
import { useProductsStore } from '@/stores/products';
import ButtonSubmit from '@/components/common/buttons/ButtonSubmit.vue';

const emits = defineEmits(['on-submit'])
const store = useProductsStore()
const form = ref({})

onBeforeMount( async() => {
    form.value = { ...store.currentProduct}
});
</script>

<style scoped>
    label {
        font-weight: bold;
    }
</style>