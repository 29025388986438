<template>
    <section class="mb-3">
        <div class="card shadow border-0 mb-3">
            <div class="card-body">
                <h4 class="mb-3">Explorar productos</h4>
                <filters-advanced-component class="col-12" />
            </div>
        </div>

        <transition-group name="list" tag="div" class="row gx-2 gy-2" mode="out-in">
            <div class="col-sm-12 col-md-12 col-lg-6" 
                v-for="item in productsStore.productsPaginated" :key="`explore-product-${item.id}`">
                <div class="card pointer border-0 shadow card-product">
                    <div class="card-body justify-content-start align-items-center d-flex">
                        <div class="image-product">
                            <img class="img-fluid rounded-circle" :src="`${baseURL}/${item.image}`" alt="">
                        </div>

                        <div>
                            <p class="fw-bold text-capitalize mb-0 product-name small">{{ item.name }}</p>
                            <small class="text-muted text-uppercase mb-0">{{ getCalculatedPrice(item) }}</small>
                        </div>

                        <div class="ms-auto quantity-selector text-end">
                            <template v-if="getQuantity(item.id) != 0">
                                <small class="mx-2 text-muted">x{{ getQuantity(item.id) }}</small>
                                <button class="btn btn-outline-secondary btn-sm me-1" type="button" id="button-addon2" @click="budgetsStore.removeToCart(item)">
                                    <i class="bi bi-dash"></i>
                                </button>
                            </template>
                            
                            <button class="btn btn-outline-theme btn-sm" type="button" id="button-addon2" @click="handleProduct(item)">
                                <i class="bi bi-plus"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <pagination-nav-component 
                class="mt-3"
                v-if="productsStore.products.length > 0" 
                :elements="productsStore.products.length" 
                :page="productsStore.page" 
                :itemsPerPage="productsStore.itemsPerPage" 
                @next-page="productsStore.nextPage" 
                @prev-page="productsStore.prevPage" 
                @change-page="productsStore.changePage"/>
        </transition-group>
    </section>
</template>

<script setup>
import { useProductsStore } from "@/stores/products";
import { useBudgetsStore } from "../../stores/budgets";
import { computed } from "vue";
import { currencyFormatted } from "@/helpers/currency";
import FiltersAdvancedComponent from "../budgets/FiltersAdvancedComponent.vue";
import PaginationNavComponent from "../common/PaginationNavComponent.vue";

const props = defineProps({
    showPrice: {
        type: String,
        default: 'PVP'
    }
})

const budgetsStore = useBudgetsStore();
const productsStore = useProductsStore();
const baseURL = process.env.VUE_APP_SERVER_BASE_URL;

const handleProduct = async(product) => {
    await budgetsStore.addToCart(product)
}

const getQuantity = (id) => {
    const item = computed(() => budgetsStore.currentBudget.products.data.find(el => el.id === id))

    if(!item.value)
        return 0
        
    return item.value.quantity
}

const getCalculatedPrice = ({ price, pricePro }) => {
    if(props.showPrice === 'PROF')
        return currencyFormatted(pricePro)

    return currencyFormatted(price)
}
</script>

<style scoped>
.image-product {
    width: 50px;
}
.image-product img {
    object-fit: cover;
    height: 40px;
    width: 40px;
}

.product-name {
    margin-bottom: -6px!important;
}
</style>