<template>
    <page-title-component title="Usuarios" icon="people" desc="Listado de usuarios para consultar sus datos." />

    <div class="container-lg mt-n10" v-if="usersStore.currentUser">
        <div class="row">
            <div class="col-md-4">
                <div class="card shadow mb-3">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <div class="d-flex align-items-center mb-1 w-100">
                                <router-link :to="{ name: 'users' }" class="btn rounded-circle btn-sm btn-outline-dark">
                                    <i class="bi bi-arrow-left"></i>
                                </router-link>
                                <h6 class="m-0 ms-2 w-100" v-html="id != 'new' ? usersStore.currentUser.name : 'Nuevo usuario'"></h6>
                                <user-avatar-badge v-if="id != 'new'" style="min-width: 35px" :name="usersStore.currentUser.name" />
                            </div>
                        </div>
                    </div>
                </div>

                <user-logs-list v-if="usersStore.currentUser.logs?.data?.length > 0" :logs="usersStore.currentUser.logs.data" />
            </div>

            <div class="col-md-8">
                <div class="card shadow mb-3" v-if="id != 'new'">
                    <div class="card-body">
                        <form @submit.prevent="submitForm">
                            <h5 class="mb-3">Datos del usuario</h5>

                            <div class="mb-3">
                                <label class="form-label fw-bold">Nombre completo</label>
                                <input type="text" class="form-control" v-model="form.name" />
                            </div>

                            <div class="mb-3">
                                <label class="form-label fw-bold">Correo electrónico</label>
                                <input type="text" class="form-control" v-model="form.email" />
                            </div>

                            <div class="mb-3">
                                <label class="form-label fw-bold">Rol / Permisos</label>
                                <select class="form-control" v-model="form.role">
                                    <option value="particular">Particular</option>
                                    <option value="profesional">Profesional</option>
                                    <option value="admin">Administrador</option>
                                </select>
                            </div>

                            <div class="mb-3">
                                <label class="form-label fw-bold">Fecha de creación</label>
                                <p>{{ dateFormatted(form.created_at) }}</p>
                            </div>


                            <div class="mb-3 text-end">
                                <ButtonSubmit icon="bi-pencil" label="Actualizar" :loading="disableForm" @on-submit="submitForm" />
                            </div>  
                        </form>
                    </div>
                </div>

                <div class="card shadow mb-3" v-if="id != 'new'">
                    <div class="card-body">
                        <form @submit.prevent="submitPassword">
                            <h5 class="mb-3">Cambiar contraseña</h5>
                            <div class="mb-3">
                                <label class="form-label fw-bold">Nueva contraseña</label>
                                
                                <div class="d-flex align-items-center justify-content-between">
                                    <input type="text" class="form-control w-50" v-model="newPassword" />
                                    <ButtonSubmit icon="bi-pencil" label="Actualizar" :loading="disableForm" @on-submit="submitPassword" />
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>

                <div class="card shadow mb-3" v-else>
                    <div class="card-body">
                        <form>
                            <h5 class="mb-3" data-v-501b2fb7="">Formulario de alta de usuarios</h5>

                            <div class="mb-3">
                                <label class="form-label">Nombre completo</label>
                                <input type="text" class="form-control" v-model="form.name" />
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Correo electrónico</label>
                                <input type="text" class="form-control" v-model="form.email" />
                            </div>

                            <div class="mb-3">
                                <label class="form-label">Rol / Permisos</label>
                                <select class="form-control" v-model="form.role">
                                    <option value="particular">Particular</option>
                                    <option value="profesional">Profesional</option>
                                    <option value="admin">Administrador</option>
                                </select>
                            </div>

                            <div class="mb-4">
                                <label class="form-label">Contraseña</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="form.password" />
                                    <button class="btn btn-outline-secondary" type="button" @click="randomPassword">Generar</button>
                                </div>
                            </div>  

                            <div class="mb-3 text-end">
                                <ButtonSubmit icon="bi-pencil" label="Crear usuario" :loading="disableForm" @on-submit="submitForm" />
                            </div>  
                        </form>
                    </div>
                </div>

                <div class="card shadow mb-3" v-if="id != 'new'">
                    <div class="card-body justify-content-between align-items-center d-flex">
                    
                        <p class="text-muted w-75 small m-0">
                            Una vez eliminado no podrás recuperar el usuario. <br /> ¿Estás seguro de que deseas eliminar-lo? 
                        </p>
                        <div>
                            <ButtonSubmit icon="bi-trash" label="Eliminar" @on-submit="destroy" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useUsersStore } from '@/stores/users';
import { useRouter } from 'vue-router'
import {dateFormatted, getLastConexion} from '@/helpers/moment'
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import UserAvatarBadge from '@/components/users/UserAvatarBadge.vue'
import UserLogsList from '@/components/users/UserLogsList.vue'
import ButtonSubmit from "@/components/common/buttons/ButtonSubmit.vue";

const props = defineProps({
    id: {
        type: String,
        required: true
    },
})

const usersStore = useUsersStore()
const router = useRouter()
const disableForm = ref(false)
const form = ref({})
const newPassword = ref('')

onBeforeMount( async() => {
  if(usersStore.users.length <= 0) {
    await usersStore.getUsers()
  }

  usersStore.getCurrentUser(props.id);
  form.value = {...usersStore.currentUser}
});

const submitForm = async() => {
    disableForm.value = true
    const result = await usersStore.submitForm(form.value)

    if(!result) {
        disableForm.value = false
        return;
    }
    router.push({name: 'users'})
}

const destroy = async() => {
    if(await usersStore.destroy(props.id)) {
        router.push({name: 'users'})
    }
}

const submitPassword = async() => {
    disableForm.value = true

    const result = await usersStore.updatePassword({ id: form.value.id, password: newPassword.value})

    if(!result) {
        disableForm.value = false
        return;
    }
    router.push({name: 'users'})
}

const randomPassword = () => {
    form.value.password = Math.random().toString(36).slice(-8);
}
</script>