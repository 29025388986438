<template>
    <div class="card border-0 shadow mb-3">
        <div class="card-body justify-content-between align-items-center d-flex">
            <p class="text-muted w-75 small m-0">
                Una vez eliminado no podrás recuperarlo. <br /> 
                ¿Estás seguro de que deseas eliminar-lo? 
            </p>

            <div>
                <ButtonSubmit icon="bi-trash" label="Eliminar" @on-submit="destroy" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useBudgetsStore } from "../../stores/budgets";
import ButtonSubmit from "@/components/common/buttons/ButtonSubmit.vue";
import { useRouter } from "vue-router";

const props = defineProps({
    id: {
        required: true
    }
})
const router = useRouter()
const store = useBudgetsStore()

const destroy = async() => {
    const resp = await store.destroy(props.id)
    if(resp)
        router.push({name: 'budgets'})
}
</script>