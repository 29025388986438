<template>
    <page-title-component title="Presupuestos" icon="coin" desc="Revisa el histórico de presupuestos generados." />

    <div class="container-lg mt-n10" v-if="store.currentBudget">
        <div class="row">
            <div class="col-md-4">
                <name-component />
                <information-component v-if="id != 'new'" @on-submit="update" />
                <form-component v-else :priceSelector="priceTypeSelector" @on-select-price="handlePriceSelector" @on-submit="submitForm" />
                <share-component v-if="id != 'new'" :id="id"/>
                <cart-item />
            </div>

            <div class="col-md-8">
                <explore-products :showPrice="priceTypeSelector" />
                <delete-component :id="id" v-if="id != 'new'" />
            </div>

        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, ref } from "vue";
import { useBudgetsStore } from '@/stores/budgets';
import { useGlobalStore } from "@/stores/globalStore";
import PageTitleComponent from '@/components/layouts/PageTitleComponent.vue'
import NameComponent from "@/components/budgets/NameComponent.vue";
import DeleteComponent from "@/components/budgets/DeleteComponent.vue";
import FormComponent from "@/components/budgets/FormComponent.vue";
import InformationComponent from '@/components/budgets/InformationComponent.vue' 
import ExploreProducts from "@/components/budgets/ExploreProducts.vue";
import CartItem from "@/components/budgets/CartItem.vue";
import ShareComponent from "@/components/budgets/ShareComponent.vue";

const store = useBudgetsStore()
const global = useGlobalStore()
const priceTypeSelector = ref('PVP')
const props = defineProps({
    id: {
        type: String,
        required: true
    },
})

onBeforeMount( async() => {
    await store.getBudgets()
    store.getcurrentBudget(props.id);
})

onBeforeUnmount( () => {
    store.currentBudget = null
})

const handlePriceSelector = (val) => {
    priceTypeSelector.value = val
}

const submitForm = (data) => {
    store.currentBudget.name = data.name
    store.currentBudget.tax = data.tax
    store.currentBudget.user_id = data.user_id
    
    store.submitForm()
}

const update = () => {
    store.submitForm(global.onLine)
}
</script>