<template>
    <div class="profile-image">{{ createInitials(name) }}</div>
</template>

<script setup>
const props = defineProps({
    name: {
        type: String,
        required: true
    }
})

const createInitials = (value) => {
    return value.split(" ")
            .map((n)=>n[0])
            .join("")
            .toUpperCase().substr(0, 2) || value[0].toUpperCase().substr(0, 2)
}

</script>