import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import server from '../api/server'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import { useGlobalStore } from "@/stores/globalStore";

export const useUsersStore = defineStore('usersStore', () => {
    const users = ref([])
    const currentUser = ref({})
    const searchInput = ref('')
    const orderFilter = ref('asc')
    const isLoading = ref(true)
    const page = ref(1)
    const itemsPerPage = ref(15)
    const globalStore = useGlobalStore();

    async function getUsers () {
        isLoading.value = true
        const { data } = await server.get(`/users`)
        const { status, results } = data

        if(!status) {
            console.log(err, 'error')
            return false
        }

        users.value = results.data.reverse().filter(el => el.id != globalStore.auth.data.id)
        isLoading.value = false
    }

    const usersPaginated = computed( () => {
        let items = []

        if(searchInput.value.length > 2) {
            items = users.value.filter( (element) => {
                return element.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0 
                || element.email.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0
            })
        } else {
            items = users.value.slice((page.value - 1) * itemsPerPage.value, ((page.value - 1) * itemsPerPage.value) + itemsPerPage.value)
        }
        
        
        return items
    })

    function nextPage(){
        if(page.value > users.value.length / itemsPerPage.value ) return;
        
        page.value++
    }

    function prevPage(){
        if((page.value - 1) < 1 ) return;
        
        page.value--
    }

    function changePage(value){
        page.value = value
    }

    function filter(event){
        const field = event.target.value
        users.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function sortDirection(arg){
        orderFilter.value = arg
        users.value.reverse()
    }

    function getCurrentUser(id){
        if(id != 'new')
        {
            currentUser.value = users.value.find( el => el.id == id) 
        } else {
            currentUser.value = {
                name: '',
                email: '',
                id: 'new'
            }
        }
    }

    async function submitForm(form) {
        const formData = new FormData()
        formData.append('user_id', form.id)
        formData.append('name', form.name)
        formData.append('email', form.email)
        formData.append('role', form.role)
        formData.append('password', form.password)

        try {
            const action = (form.id === 'new') ? '/users' : '/users/update'
            const { data } = await server.post(action, formData)
            const { status, results } = data

            if(!status) 
                return false

            if(form.id === 'new') {
                users.value.unshift(results.data)
                swalToast(`El usuario ${form.name} ha sido creado correctamente.`)
            } else {
                const index = users.value.findIndex( el => el.id == form.id) 
                users.value[index] = form
                swalToast(`El usuario ${form.name} ha sido actualizado correctamente.`)
            }
        } catch(err) {
            if(!err.response.data.errors)
                return swalToast(err.message, 'error')

            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
                return false
            });
        }

        return true
    }
    
    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas dar de baja este usuario?", "Una vez eliminado no podrá recuperarse.", "Eliminar", "Cancelar", "warning")
        if(!confirmed) return;

        try {
            const { data } = await server.delete(`users/${id}`)
            const { status } = data

            if(!status) 
                return false

            const index = users.value.findIndex( el => el.id == id) 
            users.value.splice(index, 1)
           
            swalToast("El usuario ha sido eliminado correctamente.")
            return true
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    async function updatePassword(form) {
        const formData = new FormData()
        formData.append('user_id', form.id)
        formData.append('password', form.password)

        try {
            const { data } = await server.post('/users/update/password', formData)
            const { status } = data

            if(!status) 
                return false

            swalToast('La contraseña ha sido actualizada correctamente.')
        } catch(err) {
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
        return true
    }

    return { 
        currentUser, 
        isLoading,
        itemsPerPage,
        orderFilter,
        page,
        searchInput,
        users,
        usersPaginated,
        updatePassword,
        changePage,
        destroy,
        filter,
        getCurrentUser,
        getUsers, 
        nextPage,
        prevPage,
        sortDirection, 
        submitForm,
    }
})
