<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-lg">
        <div class="container-fluid justify-content-start">
            <router-link class="navbar-brand" :to="{ name: 'home' }">
                <img src="@/assets/logo_small.png" height="60" />
            </router-link>
            
            <div class="collapse navbar-collapse justify-content-between" id="navbarNav">
                <header-link :items="navbar" @on-change="selectRoute" />

                <ul class="navbar-nav d-flex d-none d-lg-block" v-if="globalStore.status == 'authorised'">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle d-flex align-items-center justify-content-end" 
                            href="#" id="navbarDarkDropdownMenuLink" 
                            role="button" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false">
                            <user-avatar-badge :name="globalStore.auth?.data?.name" />
                        </a>
                        <ul class="dropdown-menu m-0" aria-labelledby="navbarDarkDropdownMenuLink" style="left:-100px">
                            <li class="pointer">
                                <a class="dropdown-item fw-light" target="_blank" href="#">
                                  <i class="bi bi-info-square"></i> Guía del usuario
                                </a>
                            </li>
                            <li class="pointer">
                                <a class="dropdown-item fw-light" @click="globalStore.logOut">
                                  <i class="bi bi-box-arrow-left"></i> Cerrar sesión
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>



            <button class="btn btn-sm btn-outline-success disabled" :class="globalStore.onLine ? 'btn-outline-success' : 'btn-outline-danger'">
                <i class="bi" :class="globalStore.onLine ? 'bi-wifi' : 'bi-wifi-off'">&nbsp;</i>
                {{ globalStore.onLine ? 'Online' : 'Offline'}}
            </button>

            <div class="d-flex ms-auto" v-if="globalStore.onLine">
                <ul class="navbar-nav d-block d-lg-none me-3" v-if="globalStore.status == 'authorised'">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle d-flex align-items-center justify-content-end" 
                            href="#" id="navbarDarkDropdownMenuLink" 
                            role="button" 
                            data-bs-toggle="dropdown" 
                            aria-expanded="false">
                            <user-avatar-badge :name="globalStore.auth?.data?.name" />
                        </a>
                        <ul class="dropdown-menu m-0 position-absolute" aria-labelledby="navbarDarkDropdownMenuLink" style="left:-100px">
                            <li class="pointer">
                                <a class="dropdown-item fw-light" target="_blank" href="#">
                                  <i class="bi bi-info-square"></i> Guía del usuario
                                </a>
                            </li>
                            <li class="pointer">
                                <a class="dropdown-item fw-light" @click="globalStore.logOut">
                                  <i class="bi bi-box-arrow-left"></i> Cerrar sesión
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <router-link class="btn btn-outline-theme btn-sm mx-2" v-else :to="{name: 'login'}">Iniciar sesión</router-link>

                <button class="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
        </div>
    </nav>
</template>

<script setup>
import {ref} from 'vue'
import {useGlobalStore} from '../../stores/globalStore'
import {useRouter} from 'vue-router'
import HeaderLink from '../common/HeaderLink.vue'
import UserAvatarBadge from '../users/UserAvatarBadge.vue'

const globalStore = useGlobalStore()
const router = useRouter()

const selectRoute = (item) => {
    navbar.value.map( item => {
        item.active = false
        return item
    })

    item.active = true
    router.push({ name: item.route })
}

const navbar = ref([
    {
        route: 'home',
        label: 'Inicio',
        active: true,
        icon: 'bi bi-speedometer',
    },
    {
        route: 'users',
        label: 'Usuarios',
        active: false,
        icon: 'bi bi-people',
    },
    {
        route: 'products',
        label: 'Productos',
        active: false,
        icon: 'bi bi-flower1',
    },
    {
        route: 'budgets',
        label: 'Presupuestos',
        active: false,
        icon: 'bi bi-coin',
    },
])
</script>