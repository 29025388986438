<template>
    <div class="card border-0 shadow mb-3">
        <div class="card-body">   
            <div class="text-center">
                <img :src="image" class="img-fluid" alt="Logotipo">
            </div>

            <div class="mb-3 mt-3">
                <label for="product-image" class="form-label">Actualizar imagen</label>
                <input type="file" :disabled="!globalStore.onLine" class="form-control" id="product-image" @change="handleUpload">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useProductsStore } from '@/stores/products';
import { useGlobalStore } from '@/stores/globalStore';

const emits = defineEmits(['on-submit'])
const productStore = useProductsStore()
const globalStore = useGlobalStore()
const baseURL = process.env.VUE_APP_SERVER_BASE_URL;
const image = ref(`${baseURL}/${productStore.currentProduct.image}`)

const handleUpload = (event) => {
    const file = event.target.files[0]
    image.value = URL.createObjectURL(file)
    emits('on-submit', event)
}
</script>

<style scoped>
img.img-fluid {
    height: 200px;
    width: 100%;
    max-width: 400px;
    object-fit: cover;
    border-radius: 4px;
}
</style>