<template>
    <page-title-component title="Usuarios" icon="people" desc="Listado de usuarios para consultar sus datos." />

    <div class="container-lg mt-n10">
        <loading-card v-if="usersStore.isLoading" />

        <Transition name="fade">
            <div v-if="!usersStore.isLoading">
                <filters-component />
                <button-add-new />
                <table-users />
            </div>
        </Transition>
    </div>
</template>

<script setup>
import { useUsersStore } from "../stores/users";
import FiltersComponent from '../components/users/FiltersComponent.vue'
import PageTitleComponent from '../components/layouts/PageTitleComponent.vue'
import TableUsers from '../components/users/TableUsers.vue'
import ButtonAddNew from '../components/users/ButtonAddNew.vue'
import LoadingCard from "@/components/common/LoadingCard.vue";
import { onBeforeMount } from "vue";

const usersStore = useUsersStore();

onBeforeMount( () => {
    usersStore.isLoading = false
})
</script>