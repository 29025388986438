<template>
    <section>
        <Transition name="fade">
            <div class="card shadow mb-3">
                <div class="card-body text-center pb-3">
                    <img src="@/assets/loading.gif" width="100" alt="Cargando...">
                    <p class="text-muted">Espere un momento, por favor...</p>
                </div>
            </div>
        </Transition>
    </section>
   
</template>