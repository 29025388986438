<template>
    <div class="card border-0 shadow mb-3" v-if="store.currentProduct.id != 'new'">
        <div class="card-body justify-content-between align-items-center d-flex">
            <p class="text-muted w-75 small m-0">
                Una vez eliminado no podrás recuperar el producto. <br /> ¿Estás seguro de que deseas eliminar-lo? 
            </p>

            <div>
                <ButtonSubmit icon="bi-trash" label="Eliminar" @on-submit="destroy" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useProductsStore } from '@/stores/products';
import { useRouter } from 'vue-router'
import ButtonSubmit from '../common/buttons/ButtonSubmit.vue';

const store = useProductsStore()
const router = useRouter()

const destroy = async() => {
    if(await store.destroy(store.currentProduct.id)) {
        router.push({name: 'products'})
    }
}
</script>