export default [
    {
        path: '/',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/password-reset/:token?',
        name: 'password-reset',
        props: ( route ) => {
            return {
                token: route.params.token
            }
        },
        component: () => import('../views/PasswordReset.vue')
    },
]