import { createRouter, createWebHistory } from 'vue-router'
import authRouter from './authRouter'
import adminRouter from './adminRouter'

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
    routes: [
        ...authRouter,
        ...adminRouter,
        { 
            name: '404', 
            path: '/:catchAll(.*)', 
            redirect: '/'
        },
    ]
})

export default router
