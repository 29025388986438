<template>
    <div class="card shadow-lg border-0 mb-3">
        <div class="card-body">
            <div class="container text-center">
                <div class="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                    <div class="input-group m-2">
                        <input type="text"
                            class="form-control"
                            placeholder="Buscar producto por nombre, sku o lote."
                            v-model="store.searchInput"/>
                        <span class="input-group-text">Buscar</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useBudgetsStore } from "../../stores/budgets";
const store = useBudgetsStore();
</script>