<template>  
    <div id="main">
        <PWAPromptComponent />

        <div class="loading-container d-flex flex-column align-items-center justify-content-center" v-if="globalStore.isLoading">
            <img src="@/assets/loading.gif" width="100" alt="Cargando...">
            <p class="text-muted">Espere un momento, por favor...</p>
        </div>

        <div v-if="!globalStore.isLoading">
            <Header v-if="$route.name != 'login'"/>
            <router-view v-slot="{ Component }">
                <main class="h-100 content mb-0 pb-0">
                    <component :is="Component" />
                </main>
            </router-view>
            <Footer v-if="$route.name != 'login'"/>
        </div>
    </div>
</template>

<script setup>
    import { onBeforeMount, onBeforeUnmount, watch } from 'vue'
    import { useGlobalStore } from './stores/globalStore'
    import { useProductsStore } from "./stores/products";
    import { useUsersStore } from "./stores/users";
    import { useCategoriesStore } from "./stores/categories";
    import { useBudgetsStore } from '@/stores/budgets'
    import Footer from "./components/layouts/Footer.vue";
    import Header from "./components/layouts/Header.vue";
    import PWAPromptComponent from './components/common/PWAPromptComponent.vue';

    const globalStore = useGlobalStore()
    const productsStore = useProductsStore()
    const categoriesStore = useCategoriesStore()
    const budgetsStore = useBudgetsStore()
    const usersStore = useUsersStore()

    onBeforeMount( async() => {
        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
        console.log(`Device started ${globalStore.onLine ? 'Online' : 'Offline'}`)

        if(!globalStore.onLine || !await globalStore.checkAuth())
            return globalStore.setIsLoading(false)

        await productsStore.getProducts()
        await categoriesStore.getCategories()
        await budgetsStore.getBudgets()
        await usersStore.getUsers()
        globalStore.setIsLoading(false)
        console.info("All resources loaded")   
    })

    onBeforeUnmount( () => {
        window.removeEventListener('online', updateOnlineStatus)
        window.removeEventListener('offline', updateOnlineStatus)
    })

    watch(() => globalStore.onLine, (n) => console.log(`Device is now ${ n ? 'Online' : 'Offline'}`));

    function updateOnlineStatus(e) {
        const { type } = e
        globalStore.setOnline(type === 'online')
    }

</script>

<style scoped>
    .content{
        width: 100% !important;
        height: 100%;
        min-height: 100vh;
        margin: 0;
        padding-bottom: 3rem;
    }
    .loading-container {
        min-height: 100vh;
        align-items: center;
        text-align: center;
    }
</style>
