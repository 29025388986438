<template>
    <div v-if="store.localBudgets.length > 0" class="alert alert-light rounded bg-theme shadow" role="alert">
        <div class="d-flex justify-content-between align-items-center">
            <p class="m-0 fw-bold text-dark">
                <i class="bi bi-exclamation-triangle"></i>
                Tienes presupuestos guardados en el dispositivo pendientes de subir.
            </p>

            <a class="btn btn-theme text-white btn-sm" 
                data-bs-toggle="collapse" 
                href="#collapseBox" 
                role="button" 
                aria-expanded="false" 
                aria-controls="collapseBox">
                Más información
            </a>
        </div>

        <div class="collapse multi-collapse mt-3 scrollable-content" id="collapseBox">
            <div class="d-flex justify-content-between align-items-center mb-2" v-for="item in store.localBudgets" :key="item.id">
                <p class="m-0 fw-bold">#OFFLINE-{{ item.id }} {{ item.name }}</p>

                <p class="mb-0 ms-auto px-3">Importe {{ currencyFormatted(item.import) }}</p>
                
                <button
                    type="button"
                    class="btn btn-outline-theme btn-sm"
                    :disabled="store.isLoading"
                    @click="store.uploadStoredBudgets(item)">
                    Sincronizar
                    <i class="ms-1 bi bi-upload"></i>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { currencyFormatted } from '@/helpers/currency';
import { useBudgetsStore } from '@/stores/budgets'
const store = useBudgetsStore()

</script>

<style scoped>
.scrollable-content {
    max-height: 200px;
    overflow-y: scroll;
}
</style>