<template>
    <button @click.prevent="$emit('on-submit')" :disabled="!store.onLine || loading" class="btn btn-theme d-flex justify-content-around text-white">
        <span>{{ label }}</span>
        
        <span v-if="!loading" class="btn-icon">
            <i :class="store.onLine ? icon : 'bi bi-wifi-off'"></i>
        </span>
        
        <div v-else class="spinner-border me-1 ms-3 spinner-border-sm text-light" role="status">
            <span class="visually-hidden">Cargando...</span>
        </div>
    </button>
</template>

<script setup>
import { useGlobalStore } from '@/stores/globalStore';
const store = useGlobalStore()

const emits = defineEmits(['on-submit'])
const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    icon: {
        type: String,
        default: 'bi bi-pencil',
    },
    label: {
        type: String,
        default: 'Button label',
    }
})
</script>

<style scoped>
.spinner-border {
    position: relative;
    left: 12px;
    display: inline-block;
    margin: 0 12px;
}
</style>