import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import { useLocalStorage } from "@vueuse/core"
import { useRouter } from 'vue-router'
import server from '../api/server'

export const useCategoriesStore = defineStore('categoriesStore', () => {
    const categories = useLocalStorage('categories', [])
    const isLoading = ref(true)
    const searchInput = ref('')

    async function getCategories () {
        isLoading.value = true

        try {
            const { data } = await server.get(`/categories`)
            const { status, results } = data

            if(!status) {
                console.log(err, 'error')
                return false
            }

            categories.value = results.data
            isLoading.value = false
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    async function submitForm(input) {
        const formData = new FormData()
        formData.append('name', input)

        try {
            const { data } = await server.post('/categories', formData)
            const { status, results } = data

            if(!status) return false

            
            categories.value.unshift(results.data)
            swalToast("Nueva familia generada.")
        } catch(err) {
            console.log(err, 'error')
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
    }

    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar esta categoría?", "Los productos vinculados a esta categoría no se verán afectados", "Eliminar", "Cancelar", "warning")
        if(!confirmed) return;

        try {
            const { data } = await server.delete(`categories/${id}`)
            const { status } = data

            if(!status) {
                console.log(err, 'error')
                return false
            }

            const index = categories.value.findIndex( el => el.id == id) 
            categories.value.splice(index, 1)
           
            swalToast("El proveedor ha sido eliminado correctamente.")
            return true
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    function setSearchInput(args) {
        searchInput.value = args
    }

    return { 
        isLoading,
        searchInput,
        categories: computed( () => categories.value.filter( el => el.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0)), 
        destroy,
        getCategories, 
        setSearchInput,
        submitForm,
    }
})
