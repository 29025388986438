<template>
    <div class="card border-0 shadow mb-3">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mt-2">
                <div class="d-flex align-items-center mb-1">

                    <router-link :to="{ name: 'products' }" class="btn rounded-circle btn-sm btn-outline-dark">
                        <i class="bi bi-arrow-left"></i>
                    </router-link>

                    <h4 class="m-0 ms-2">
                        {{ store.currentProduct.name 
                            ? store.currentProduct.name 
                            : 'Nuevo producto' }}
                    </h4>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useProductsStore } from '@/stores/products';
const store = useProductsStore()
</script>