import BudgetsView from '@/views/BudgetsView'
import HomeView from '@/views/HomeView'
import isAuth from './guards/isAuth'
import OfflineView from '../views/OfflineView.vue'
import ProductsView from '@/views/ProductsView'
import SingleBudgetView from '@/views/details/SingleBudgetView'
import SingleProductView  from '@/views/details/SingleProductView'
import SingleUserView from '@/views/details/SingleUserView'
import UsersView from '@/views/UsersView'
import isOnline from './guards/isOnline'
import isOffline from './guards/isOffline'

export default [
    {
        path: '/home',
        name: 'home',
        beforeEnter: [isAuth, isOnline],
        component: HomeView
    },
    {
        path: '/users',
        name: 'users',
        beforeEnter: [isAuth, isOnline],
        component: UsersView
    },
    {
        path: '/products',
        name: 'products',
        beforeEnter: [isAuth, isOnline],
        component: ProductsView
    },
    {
        path: '/budgets',
        name: 'budgets',
        beforeEnter: [isAuth, isOnline],
        component: BudgetsView
    },
    {
        path: '/offline',
        name: 'offline',
        beforeEnter: [isOffline],
        component: OfflineView
    },
    {
        path: '/users/:id',
        name: 'users-edit',
        beforeEnter: [isAuth, isOnline],
        component: SingleUserView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/products/:id',
        name: 'products-edit',
        beforeEnter: [isAuth, isOnline],
        component: SingleProductView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
    {
        path: '/budgets/:id',
        name: 'budgets-edit',
        beforeEnter: [isAuth],
        component: SingleBudgetView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        }
    },
]