<template>
    <div class="card border-0 shadow mb-3">
        <div class="card-header pt-3 pb-0 d-flex justify-content-between">
            <h5>Familias</h5>
            <p class="text-muted">{{ productsStore.currentProduct.categories.data.length }} selec.</p>
        </div>
        <div class="card-body">  
            <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Buscar..." v-model="categoriesStore.searchInput">
                <button v-if="categoriesStore.searchInput.length > 0" @click="categoriesStore.setSearchInput('')" class="btn btn-outline-secondary btn-sm" type="button" id="button-remove-search">
                    <i class="bi bi-x"></i>
                </button>
            </div>

            <ul class="list-group list-group-flush mb-3" style="max-height: 300px; overflow-y: scroll">
                <li class="list-group-item px-0 py-1 d-flex" 
                    v-for="category in categoriesStore.categories" 
                    :key="`category-selector-${category.id}`">

                    <input 
                        :disabled="!globalStore.onLine" 
                        class="form-check-input me-2" 
                        type="checkbox" 
                        value="" 
                        :id="`input-cat-${category.id}`" 
                        :checked="isSelected(category.id) ? true : false" 
                        @click="select(category)">

                    <label class="form-check-label pointer" :for="`input-cat-${category.id}`">
                        <small>{{ category.name }}</small>
                    </label>

                    <button class="ms-auto btn btn-sm btn-outline-theme border-0" @click="categoriesStore.destroy(category.id)">
                        <i class="bi bi-trash"></i>
                    </button>
                </li>
            </ul>

            <div class="input-group flex-nowrap">
                <span class="input-group-text" id="addon-wrapping">
                    <i class="bi bi-plus"></i>
                </span>
                <input type="text" v-model="newCategory" class="form-control" placeholder="Nueva familia">
                <button class="btn btn-outline-theme" type="button" id="button-addon2" @click="addCategory()">Agregar</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useProductsStore } from '@/stores/products';
import { useGlobalStore } from '@/stores/globalStore';
import { useCategoriesStore } from '@/stores/categories';
import { ref } from 'vue';

const productsStore = useProductsStore()
const categoriesStore = useCategoriesStore()
const globalStore = useGlobalStore()
const newCategory = ref('')

const select = (category) => {
    if(!globalStore.onLine)
        return

    if(!category)
        return

    productsStore.attachCategory(category)
}

const isSelected = (id) => {
    const item = productsStore.currentProduct.categories.data.find(el => el.id === id)

    if(!item)
        return false

    return true
}

async function addCategory() {
    await categoriesStore.submitForm(newCategory.value)
    newCategory.value = ''
}


</script>