<template>
    <div class="card border-0 shadow-lg mb-3">
        <div class="card-body">
            <div class="container text-center">
                <div class="d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between">
                    <div class="input-group m-2">
                        <label class="input-group-text">Ordenar</label>
                        <button class="btn" 
                            :class="productsStore.orderFilter == 'desc' ? 'btn-theme' : 'btn-outline-theme'"
                            :disabled="productsStore.orderFilter == 'desc'"
                            @click="productsStore.sortDirection('desc')">
                            <i class="bi bi-sort-down"></i>
                        </button>
                        <button class="btn"
                            :class="productsStore.orderFilter == 'asc' ? 'btn-theme' : 'btn-outline-theme'"
                            :disabled="productsStore.orderFilter == 'asc'"
                            @click="productsStore.sortDirection('asc')">
                            <i class="bi bi-sort-up"></i>
                        </button>
                        <select class="form-select" @change="productsStore.filter($event)">
                            <option selected value="0">por defecto</option>
                            <option value="name">por nombre</option>
                            <option value="sku">por sku</option>
                            <option value="updated_at">por actualizado</option>
                        </select>
                    </div>

                    <div class="input-group m-2">
                        <input type="text"
                            class="form-control"
                            placeholder="Buscar producto por nombre, sku o lote."
                            v-model="productsStore.searchInput"/>
                        <span class="input-group-text">Buscar</span>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useProductsStore } from "../../stores/products";
const productsStore = useProductsStore();
</script>