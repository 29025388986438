<template>
    <div class="card shadow border-0 mb-3">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center mt-2">
                <div class="d-flex align-items-center mb-1 w-100">
                    <router-link :to="{ name: 'budgets' }" class="btn rounded-circle btn-sm btn-outline-dark">
                        <i class="bi bi-arrow-left"></i>
                    </router-link>

                    <h4 class="m-0 ms-2">
                        {{ store.currentBudget.name 
                            ? store.currentBudget.name 
                            : 'Nuevo presupuesto' }}
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useBudgetsStore } from "../../stores/budgets";
const store = useBudgetsStore();
</script>