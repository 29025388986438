<template>
    <div class="card shadow border-0">
        <div class="card-body table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col" class="fw-bold">Fecha</th>
                        <th scope="col" class="fw-bold">Presupuesto Nº</th>
                        <th scope="col" class="fw-bold">Usuario</th>
                        <th scope="col" class="fw-bold">Título</th>
                        <th scope="col" class="fw-bold">Importe</th>
                        <th></th>
                    </tr>
                </thead>

                <TransitionGroup name="list" tag="tbody">
                    <tr v-for="(item, i) in store.budgetsPaginated" :key="i">
                        <td>{{ dateFormatted(item.updated_at) }}</td>
                        <td>{{ item.id }}</td>
                        <td>{{ item.user.data.name }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ currencyFormatted(item.import) }}</td>
                        <td class="text-end">
                            <button-icon 
                                icon="bi bi-eye" 
                                route="budgets-edit" 
                                :canOnline="true" 
                                :params="{id: item.id}" />
                        </td>
                    </tr>

                    <no-results 
                        v-if="store.budgetsPaginated.length <= 0" 
                        colspan="5" 
                        key="no-results" />

                </TransitionGroup>
            </table>
            
            <pagination-nav-component 
                v-if="store.budgets.length > 0" 
                :elements="store.budgets.length" 
                :page="store.page" 
                :itemsPerPage="store.itemsPerPage" 
                @next-page="store.nextPage" 
                @prev-page="store.prevPage" 
                @change-page="store.changePage"/>
        </div>
    </div>
</template>

<script setup>
import { currencyFormatted } from '@/helpers/currency'
import { dateFormatted, getYear } from '../../helpers/moment'
import { useBudgetsStore } from "../../stores/budgets";
import ButtonIcon from "@/components/common/buttons/ButtonIcon.vue";
import NoResults from '@/components/common/tables/NoResults.vue'
import PaginationNavComponent from '../common/PaginationNavComponent.vue'

const store = useBudgetsStore();
</script>