<template>
    <div class="card shadow border-0 mb-3">
        <div class="card-body">
            <h4 class="mb-3">Compartir</h4>

            <div class="d-flex gap-3">
                <ButtonSubmit 
                    :loading="sending"
                    class="w-100" 
                    icon="bi-envelope" 
                    label="Enviar" 
                    @on-submit="shareBudget" />

                <ButtonSubmit 
                    :loading="downloading"
                    class="w-100" 
                    icon="bi-download" 
                    label="Descargar" 
                    @on-submit="downloadBudget" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { swalToast } from '@/helpers/swal';
import ButtonSubmit from '../common/buttons/ButtonSubmit.vue';
import server from '@/api/server';

const baseURL = process.env.VUE_APP_SERVER_BASE_URL
const downloading = ref(false)
const sending = ref(false)

const props = defineProps({
    id: {
        required: true
    }
})

const shareBudget = async() => {
    try {
        sending.value = true
        const { data } = await server.get(`/budgets/share/${props.id}`)

        if(!data.status) {
            return
        }

        swalToast("El presupuesto ha sido enviado correctamente.")
        sending.value = false
    } catch(err) {
        console.log(err, 'error')
        swalToast(err, "error")
    }
}

const downloadBudget = async() => {
    try {
        downloading.value = true
        const { data } = await server.get(`/budgets/download/${props.id}`, {
            Accept: 'application/pdf',
        })

        if(!data.status) {
            return
        }
        window.open(`${baseURL}/${data.message}`);
        downloading.value = false
    } catch(err) {
        console.log(err, 'error')
        swalToast(err, "error")
    }
}
</script>