<template>
    <div class="card border-0 shadow mb-3">
        <div class="card-body">
            <router-link :to="{ name: 'products-edit', params: { id: 'new'} }" class="text-theme text-decoration-none pe-auto pointer">
                <div class="card-body text-center d-flex justify-content-center align-items-center">
                    <i class="bi bi-plus h1 m-0"></i>
                    <p class="m-0">Crear nuevo</p>
                </div>
            </router-link>
        </div>
    </div>
</template>