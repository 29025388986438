<template>
    <div id="download-section" class="w-100 py-2 px-1 gap-2 align-items-center justify-content-start d-flex" v-if="shown">
        <a class="px-1 pointer" @click="dismissPrompt">
            <i class="bi bi-x-circle text-white"></i>
        </a>

        <div>
            <img src="@/assets/favicon.png" height="50" />
        </div>

        <div>
            <h6 class="mb-1 text-white">{{ appTitle }}</h6>
            <small class="text-light text-small mb-0">Descargar la aplicación en tu dispositivo</small>
        </div>

        <button class="ms-auto me-3 px-3 py-1 btn btn-rounded btn-sm btn-light" @click="installPWA">OBTENER</button>
    </div>
</template>

<style scoped>
#download-section {
    z-index: 99;
    background-color: #1c3c34;
}
.btn.btn-rounded {
    border-radius: 50px;
    font-weight: 600;
}
img {
    border-radius: 12px;
}
</style>
  
<script setup>
    import { ref, onBeforeMount } from 'vue'
      
    const shown = ref(false)
    const installEvent = ref()
    const appTitle = ref(process.env.VUE_APP_TITLE)

    onBeforeMount( async() => {
        if(checkLocalStorage())
            return

        window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        installEvent.value = e
        
        if (window.matchMedia('(display-mode: standalone)').matches)
            return

        shown.value = true
      })
    })
  
    function dismissPrompt() {
        const now = new Date()
        localStorage.setItem('ignore-pwa-prompt', now.getTime() + 3600000)
        shown.value = false
    }

    function checkLocalStorage() {
        const storagePrompt = localStorage.getItem('ignore-pwa-prompt')
        const now = new Date()

        if (!storagePrompt)
            return false

        if (now.getTime() > storagePrompt) {
            localStorage.removeItem('ignore-pwa-prompt')
            return false
        }

        return true
    }

    function installPWA() {
        console.log("installEvent", installEvent.value)
        installEvent.value.prompt()
        installEvent.value.userChoice.then((choice) => {
            dismissPrompt()

            if (choice.outcome === 'accepted') {
                // Do something additional if the user chose to install
            } else {
                // Do something additional if the user declined
            }
        })
    }
</script>