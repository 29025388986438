<template>
    <router-link :class="!store.onLine && !canOnline ? 'disabled' : ''" :to="{ name: route, params }" class="btn btn-outline-theme btn-sm shadow me-1">
        <i :class="icon"></i>
    </router-link>
</template>

<script setup>
import { useGlobalStore } from '@/stores/globalStore';
const store = useGlobalStore()

const emits = defineEmits(['on-submit'])
const props = defineProps({
    icon: {
        type: String,
        default: 'bi bi-pencil',
    },
    params: {
        type: Object,
        default: {}
    },
    route: {
        type: String,
        required: true,
    },
    canOnline: {
        type: Boolean,
        default: true
    }
})
</script>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>