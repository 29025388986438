import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { swalToast, swalQuestionCustom } from '../helpers/swal'
import { useLocalStorage } from "@vueuse/core"
import { useRouter } from 'vue-router'
import server from '../api/server'
import moment from 'moment'

export const useProductsStore = defineStore('productsStore', () => {
    const categorySelected = ref(null)
    const currentProduct = ref({})
    const isLoading = ref(true)
    const dateSync = useLocalStorage('dateSync', null)
    const itemsPerPage = ref(15)
    const orderFilter = ref('asc')
    const page = ref(1)
    const products = useLocalStorage('products', [])
    const router = useRouter()
    const searchInput = ref('')

    const productsPaginated = computed( () => {
        let items = []

        if(categorySelected.value != null) {
            if(searchInput.value.length > 0)
                setCategory(null)

            items = products.value.filter( (element) => element.categories?.data.some(el => el.id === categorySelected.value?.id))
            return items
        }

        if(searchInput.value.length > 2) {
            items = products.value.filter( (element) => {
                return element.name.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0 
                || element.sku.toLowerCase().indexOf(searchInput.value.toLowerCase()) >= 0
            })
        } else {
            items = products.value.slice((page.value - 1) * itemsPerPage.value, ((page.value - 1) * itemsPerPage.value) + itemsPerPage.value)
        }
        
        return items
    })

    async function getProducts() {
        isLoading.value = true

        try {
            const { data } = await server.get(`/products`)
            const { status, results } = data

            if(!status) {
                console.log(err, 'error')
                return false
            }
            products.value = results.data.reverse()
            dateSync.value = moment().format('DD/MM/YYYY')
            isLoading.value = false
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    function nextPage(){
        if(page.value >= Math.ceil(products.value.length / itemsPerPage.value) ) return;
        
        page.value++
    }

    function prevPage(){
        if((page.value - 1) < 1 ) return;
        
        page.value--
    }

    function changePage(value){
        page.value = value
    }
        
    function filter(event){
        const field = event.target.value
        products.value.sort((a, b) => (a[field] < b[field]) ? -1 : 1)
    }

    function sortDirection(arg){
        orderFilter.value = arg
        products.value.reverse()
    }

    function getCurrentProduct(id){
        if(id != 'new')
        {
            currentProduct.value = products.value.find( el => el.id == id) 
        } else {
            currentProduct.value = {
                id: 'new',
                name: '',
                sku: '',
                price: 0,
                description: '',
                observations: '',
                categories: { data: [] },
                image: 'assets/img/default_product.png'
            }
        }
    }

    async function destroy(id) {
        const confirmed = await swalQuestionCustom("¿Deseas eliminar este producto?", "Una vez eliminado no podrás recuperarlo", "Eliminar", "Cancelar", "warning")
        if(!confirmed) return;

        try {
            const { data } = await server.delete(`products/${id}`)
            const { status } = data

            if(!status) {
                console.log(err, 'error')
                return false
            }

            const index = products.value.findIndex( el => el.id == id) 
            products.value.splice(index, 1)
           
            swalToast("El producto ha sido eliminado correctamente.")
            return true
        } catch(err) {
            console.log(err, 'error')
            return false
        }
    }

    async function submitForm(form) {
        const formData = new FormData()

        formData.append('product_id', form.id)
        for (const [key, value] of Object.entries(form)) {
            if(key != 'image')
                formData.append(key, value)
        }
        formData.append('categories', JSON.stringify(currentProduct.value.categories.data.map(x => x.id)))

        if(form.file) 
            formData.append('image', form.file)

        const action = (form.id === 'new') ? '/products' : '/products/update'
        try {
            const { data } = await server.post(action, formData, { headers: { 'Content-Type': 'multipart/form-data' }})
            const { status, results, message } = data

            if(!status) 
                return false

            if(form.id === 'new') {
                products.value.unshift(results.data)
            } else {
                const index = products.value.findIndex( el => el.id == results.data.id) 
                products.value[index] = results.data
            }

            swalToast(message)
            router.push({name: 'products'})
        } catch(err) {
            console.log(err, 'error')
            Object.keys(err.response.data.errors).forEach(key => {
                swalToast(err.response.data.errors[key][0], 'error')
            });
            return false
        }
    }

    async function attachCategory(category) {
        const categoriesSelected = [...currentProduct.value.categories.data.map(x => x.id)]

        if(categoriesSelected.includes(category.id)) {
            const index = currentProduct.value.categories.data.findIndex(el => el.id === category.id)
            currentProduct.value.categories.data.splice(index, 1)
        } else {
            currentProduct.value.categories.data.push(category)    
        }
    }

    function setCategory(item) {
        categorySelected.value = item
    }

    return { 
        currentProduct, 
        isLoading,
        itemsPerPage,
        orderFilter,
        page,
        products,
        productsPaginated,
        searchInput,
        getDateSync: computed( () => dateSync.value),
        getCategorySelected: computed( () => categorySelected.value),
        attachCategory,
        changePage,
        destroy,
        filter,
        getCurrentProduct,
        getProducts, 
        nextPage,
        prevPage,
        sortDirection, 
        setCategory,
        submitForm,
    }
})
