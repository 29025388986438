<template>
    <div class="card shadow">
        <div class="card-body table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th scope="col" class="fw-bold">Nombre</th>
                        <th scope="col" class="fw-bold">Email</th>
                        <th></th>
                    </tr>
                </thead>
                <TransitionGroup name="list" tag="tbody">
                        <tr v-for="(user, i) in usersStore.usersPaginated" :key="i">
                            <th scope="row" class="logo">
                                <user-avatar-badge :name="user.name" />
                            </th>
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td> 
                                <button-icon icon="bi bi-eye" :canOnline="true" route="users-edit" :params="{id: user.id}" />
                            </td>
                        </tr>
                        <no-results v-if="usersStore.usersPaginated.length <= 0" colspan="3" key="no-results" />

                    </TransitionGroup>
            </table>
            
            <pagination-nav-component 
                v-if="usersStore.users.length > 0" 
                :elements="usersStore.users.length" 
                :page="usersStore.page" 
                :itemsPerPage="usersStore.itemsPerPage" 
                @next-page="usersStore.nextPage" 
                @prev-page="usersStore.prevPage" 
                @change-page="usersStore.changePage"/>
        </div>
    </div>
</template>

<script setup>
import { useUsersStore } from "../../stores/users";
import ButtonIcon from "../common/buttons/ButtonIcon.vue";
import NoResults from '@/components/common/tables/NoResults.vue'
import PaginationNavComponent from '../common/PaginationNavComponent.vue'
import UserAvatarBadge from '../../components/users/UserAvatarBadge.vue'

const usersStore = useUsersStore();

</script>

<style scoped>
    .logo {
    width: 65px;
    }
    .logo img {
        object-fit: cover;
        height: 40px;
        width: 40px;
    }
    .badge {
        min-width: 35px;
    }
</style>