export const IVA = 21;

export const currencyFormatted = (value) => {
    return (value).toLocaleString('es-ES', {
        style: 'currency',
        currency: 'EUR',
    })
}

export const getIvaPrice = (price) => {
    return price + (price * IVA / 100)
}