<template>
    <page-title-component title="Sin conexión" icon="wifi-off" desc="El dispositivo no tiene conexión a internet" />

    <div class="container mt-n10">
        <div class="row">
            <div class="col-12 mb-3">
                <div class="card shadow mb-3 border-0">
                    <div class="card-body">
                        <button class="btn btn-outline-theme btn-sm position-absolute top-0 end-0 m-2">
                            <i class="bi bi-arrow-repeat"></i>
                        </button>
                        <h5 class="card-title mb-3">No tienes conexión a internet</h5>
                        <p class="card-text fw-bold mb-1">Última actualización del catálogo: <span>{{ productStore.getDateSync }}</span></p>
                        <p class="card-text fw-bold mb-1">Número de productos almacenados: <span>{{ productStore.products.length }}</span></p>

                        <div class="d-flex gap-2 mt-4">
                            <router-link :to="{name: 'budgets-edit', params: {id: 'new'}}" class="btn w-100 btn-outline-theme">Iniciar presupuesto</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <local-budgets-list />
            <explore-products />
        </div>
    </div>
</template>

<script setup>
import { useProductsStore } from '@/stores/products';
import ExploreProducts from '@/components/products/ExploreProducts.vue';
import PageTitleComponent from '../components/layouts/PageTitleComponent.vue'
import LocalBudgetsList from '@/components/budgets/LocalBudgetsList.vue'
const productStore = useProductsStore()
</script>