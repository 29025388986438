<template>
    <div class="card shadow border-0 mb-3" >
        <div class="card-body">
            <form>
                <h5 class="mb-3">Información básica</h5>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Mostrar precios</label>
                    <div>
                        <div class="d-flex w-100 gap-1">
                            <button type="button" :class="priceSelector === 'PVP' ? 'btn-theme' : 'btn-secondary'" class="w-100 btn text-white" @click="$emit('on-select-price', 'PVP')">PVP</button>
                            <button type="button" :class="priceSelector === 'PROF' ? 'btn-theme' : 'btn-secondary'" class="w-100 btn text-white" @click="$emit('on-select-price', 'PROF')">PROFESIONAL</button>
                        </div>
                    </div>
                </div>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Usuario</label>
                    <select v-model="form.user_id" @change="attachUser" class="form-control">
                        <option :value="undefined">- Selecciona -</option>
                        <option :value="user.id" v-for="user in userStore.users" :key="user.id">
                            {{ user.name }} - {{ user.role_label }}
                        </option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Tipo de IVA</label>
                    <select v-model="form.tax" class="form-control" placeholder="Tipo de IVA">
                        <option value="0">EXENTO</option>
                        <option value="10">10%</option>
                    </select>
                </div>

                <div class="mb-3">
                    <label class="form-label fw-bold mb-0">Título del presupuesto</label>
                    <input type="text" class="form-control" v-model="form.name" />
                </div>
                

                <div class="mb-3">
                    <button @click.prevent="$emit('on-submit', form)" class="w-100 btn btn-theme text-white">
                        <span>Crear presupuesto</span>
                        
                        <span class="btn-icon">
                            <i class="bi-save"></i>
                        </span>
                    </button>
                </div>  
            </form>
        </div>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";
import { useBudgetsStore } from '@/stores/budgets';
import { useUsersStore } from "@/stores/users";

const emits = defineEmits(['on-submit', 'on-select-price'])
const store = useBudgetsStore()
const userStore = useUsersStore()
const form = ref({})
const props = defineProps({
    priceSelector: {
        type: String,
        default: 'PVP'
    }
})

const attachUser = (event) => {
    const userId = event.target.value
    store.currentBudget.user.data = userStore.users.find(el => el.id == userId)
}

onBeforeMount( async() => {
    await userStore.getUsers()
    form.value = { ...store.currentBudget }
});
</script>