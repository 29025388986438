<template>
    <section class="mb-3">
        <div class="card shadow border-0 mb-3">
            <div class="card-body">
                <h4 class="mb-3">Explorar productos</h4>
                <filters-advanced-component class="col-12" />
            </div>
        </div>

        <transition-group name="list" tag="div" class="row gx-2 gy-2" mode="out-in">
            <div class="col-sm-12 col-md-6 col-lg-4" 
                v-for="item in productsStore.productsPaginated"
                :key="`explore-product-${item.id}`">
                <div class="card pointer border-0 shadow card-product">
                    <div class="card-body justify-content-start align-items-center d-flex">
                        <div class="image-product">
                            <img class="img-fluid rounded-circle p-1" src="@/assets/wifi-off.svg" alt="Icono">
                        </div>

                        <div>
                            <p class="fw-bold text-capitalize mb-0 product-name">{{ item.name }}</p>
                            <small class="text-muted text-uppercase mb-0">{{ item.sku }}</small>
                        </div>
                    </div>
                </div>
            </div>

            <pagination-nav-component 
                class="mt-3"
                v-if="productsStore.products.length > 0" 
                :elements="productsStore.products.length" 
                :page="productsStore.page" 
                :itemsPerPage="productsStore.itemsPerPage" 
                @next-page="productsStore.nextPage" 
                @prev-page="productsStore.prevPage" 
                @change-page="productsStore.changePage"/>
        </transition-group>
    </section>
</template>

<script setup>
import { useProductsStore } from "@/stores/products";
import FiltersAdvancedComponent from "@/components/budgets/FiltersAdvancedComponent.vue";
import PaginationNavComponent from "@/components/common/PaginationNavComponent.vue";

const productsStore = useProductsStore();
</script>

<style scoped>
.image-product {
    width: 50px;
}
.image-product img {
    object-fit: cover;
    height: 40px;
    width: 40px;
}

.product-name {
    margin-bottom: -6px!important;
}
</style>